// This code initiates ad rotation calls for the caas image lightbox
(function (window) {
    const IMAGE_LIGHTBOX_NAME = 'caas-image-lightbox';
    const { wafer } = window;

    const setup = () => {
        const { wafer } = window;

        wafer.on('lightbox:open', (data) => {
            if (data.elem.getElementsByClassName(IMAGE_LIGHTBOX_NAME)[0]) {
                const { DARLA } = window;
                DARLA && DARLA.stopAuto();
            }
        });

        wafer.on('lightbox:close', (data) => {
            if (data.elem.getElementsByClassName(IMAGE_LIGHTBOX_NAME)[0]) {
                const { DARLA, wafer } = window;
                if (DARLA && wafer) {
                    const autoEventSettings = DARLA.evtSettings('AUTO');
                    if (autoEventSettings) {
                        // Rotate all ads at least 50% in the viewport
                        const autoEventPositions = Object.keys(
                            autoEventSettings.ps || {}
                        );
                        const viewportPositions = autoEventPositions.filter(
                            (pos) => {
                                const posSettings =
                                    DARLA.posSettings(pos) || {};
                                const posEl =
                                    posSettings.clean &&
                                    document.getElementById(posSettings.clean);
                                const { clientHeight, clientWidth } =
                                    posEl || {};
                                return !!(
                                    posEl &&
                                    clientHeight &&
                                    clientWidth &&
                                    wafer.utils.elementInView(
                                        posEl,
                                        {
                                            offsetX: -(clientWidth / 2),
                                            offsetY: -(clientHeight / 2),
                                        },
                                        wafer.base.viewport
                                    )
                                );
                            }
                        );

                        if (viewportPositions.length) {
                            if (DARLA.inProgress()) {
                                DARLA.abort();
                            }

                            DARLA.add(IMAGE_LIGHTBOX_NAME, {
                                groups: autoEventSettings.groups,
                                name: IMAGE_LIGHTBOX_NAME,
                                ps: viewportPositions.join(','),
                                ref: autoEventSettings.ref,
                                sa: autoEventSettings.sa,
                                sp: autoEventSettings.sp,
                                ult: autoEventSettings.ult,
                            });

                            DARLA.event(IMAGE_LIGHTBOX_NAME);
                        }
                    }
                    DARLA.startAuto();
                }
            }
        });
    };

    if (wafer) {
        wafer.ready(function () {
            setup();
        }, window);
    } else {
        document.body.addEventListener('wafer:ready', function () {
            setup();
        });
    }
})(window);
